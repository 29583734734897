import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";

import SideBar from "../components/sidebar";
import Header from "../components/header";

// import type
import { FC, ReactNode } from "react";

interface LayoutsProps {
  children?: ReactNode;
}

const Layouts: FC<LayoutsProps> = ({ children }) => {
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        maxWidth: "1920px",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      <Grid
        item
        lg={4}
        xl={3}
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "block" },
          padding: "40px 40px 26px 40px",
        }}
      >
        <SideBar />
      </Grid>
      <Grid
        item
        xs={12}
        lg={8}
        xl={9}
        sx={{
          paddingLeft: { xs: "20px", md: "40px", lg: "0px" },
          paddingRight: { xs: "20px", md: "40px" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            width: "100%",
            zIndex: 999,
          }}
        >
          <Header />
        </Box>
        {children}
      </Grid>
    </Grid>
  );
};

Layouts.propTypes = {
  children: PropTypes.node,
};

export default Layouts;
