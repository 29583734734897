import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// ** Import MUI
import {
	Box,
	Card,
	Tab,
	Divider,
	Tabs,
	TextField,
	InputAdornment,
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

// ** Import Type
import type { FC, ChangeEvent, FormEvent, MouseEvent } from 'react';
import type { WebsiteData } from '../../types/pdf-data';

// ** Import children component
import DedicateWebsitesTable from './dedicate-websites-table';

// ** import action
import { getWebsites } from '../../store/data/actions';

// ** Type of Tab
type TabValue =
	| 'all'
	| 'ready'
	| 'scraping'
	| 'scraping failed'
	| 'scraped'
	| 'training'
	| 'training failed'
	| 'trained';
interface Filters {
	query?: string;
	status: TabValue;
}
interface TabType {
	label: string;
	value: TabValue;
}
const tabs: TabType[] = [
	{
		label: 'All',
		value: 'all',
	},
	{
		label: 'Ready',
		value: 'ready',
	},
	{
		label: 'Scraping',
		value: 'scraping',
	},
	{
		label: 'Scraping Failed',
		value: 'scraping failed',
	},
	{
		label: 'Scraped',
		value: 'scraped',
	},
	{
		label: 'Training',
		value: 'training',
	},
	{
		label: 'Training failed',
		value: 'training failed',
	},
	{
		label: 'Trained',
		value: 'trained',
	},
];

// ** Type of sort
type Sort = 'updated_at|desc' | 'updated_at|asc' | 'url|desc' | 'url|asc';
interface SortOption {
	label: string;
	value: Sort;
}
const sortOptions: SortOption[] = [
	{
		label: 'Asc by file url',
		value: 'url|asc',
	},
	{
		label: 'Desc by file url',
		value: 'url|desc',
	},
	{
		label: 'Last update (newest)',
		value: 'updated_at|desc',
	},
	{
		label: 'Last update (oldest)',
		value: 'updated_at|asc',
	},
];

// ! Start component here
const DedicateWebsites: FC = () => {
	const dispatch = useDispatch();
	const { websiteData } = useSelector((state: any) => state?.data);
	const [currentTab, setCurrentTab] = useState<TabValue>('all');
	const [filters, setFilters] = useState<Filters>({
		query: '',
		status: 'all',
	});
	const queryRef = useRef<HTMLInputElement | null>(null);
	const [sort, setSort] = useState<Sort>('updated_at|desc');
	const [page, setPage] = useState<number>(websiteData.currentPage - 1);
	const [rowsPerPage, setRowsPerPage] = useState<number>(websiteData.pageSize);
	const [websites, setWebsites] = useState<WebsiteData[]>(websiteData.data);
	const [total, setTotal] = useState<number>(websiteData.total);

	useEffect(() => {
		applyPagination(filters, sort, page, rowsPerPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort, page, rowsPerPage, filters]);

	useEffect(() => {
		setWebsites(websiteData.data);
		setTotal(websiteData.total);
	}, [websiteData]);

	const handleQueryChange = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		setFilters((prevState) => ({
			...prevState,
			query: queryRef.current?.value,
		}));
	};

	const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setSort(event.target.value as Sort);
		setPage(0);
	};

	const handleTabsChange = (event: ChangeEvent<{}>, value: TabValue): void => {
		const updatedFilters: Filters = {
			...filters,
		};

		updatedFilters.status = value;

		setFilters(updatedFilters);
		setCurrentTab(value);
		setPage(0);
	};

	const applyPagination = (
		filters: Filters,
		sort: Sort,
		page: number,
		rowsPerPage: number
	) => {
		dispatch(getWebsites({ filters, sort, page, rowsPerPage }));
	};

	const handlePageChange = (
		event: MouseEvent<HTMLButtonElement> | null,
		newPage: number
	): void => {
		setPage(newPage);
	};

	const handleRowsPerPageChange = (
		event: ChangeEvent<HTMLInputElement>
	): void => {
		setRowsPerPage(parseInt(event.target.value, 50));
	};

	return (
		<Box sx={{ marginX: '-24px' }}>
			<Box
				component="div"
				sx={{
					flexGrow: 1,
					pb: 3,
				}}
			>
				<Card>
					<Tabs
						indicatorColor="primary"
						onChange={handleTabsChange}
						scrollButtons="auto"
						sx={{ px: 3 }}
						textColor="primary"
						value={currentTab}
						variant="scrollable"
					>
						{tabs.map((tab) => (
							<Tab key={tab.value} label={tab.label} value={tab.value} />
						))}
					</Tabs>
					<Divider />
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexWrap: 'wrap',
							m: -1.5,
							p: 3,
						}}
					>
						<Box
							component="form"
							onSubmit={handleQueryChange}
							sx={{
								flexGrow: 1,
								m: 1.5,
							}}
						>
							<TextField
								defaultValue=""
								fullWidth
								inputProps={{ ref: queryRef }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchRoundedIcon />
										</InputAdornment>
									),
								}}
								placeholder="Search Documents"
							/>
						</Box>
						<TextField
							label="Sort By"
							name="sort"
							onChange={handleSortChange}
							select
							SelectProps={{ native: true }}
							sx={{ m: 1.5, width: { xs: '100%', md: 'auto' } }}
							value={sort}
						>
							{sortOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Box>
					<DedicateWebsitesTable
						data={websites}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						rowsPerPage={rowsPerPage}
						page={page}
						total={total}
					/>
				</Card>
			</Box>
		</Box>
	);
};

export default DedicateWebsites;
