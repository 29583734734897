import React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

// ** Import MUI
import { Typography, Button, Box, Grid } from '@mui/material';
const Upgrade: FC = () => {
	return (
		<Box sx={{ marginBottom: '19px', marginTop: '5px', width: '100%' }}>
			<Box
				sx={{
					textAlign: 'center',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				<Typography
					sx={{
						color: '#bfa600',
						marginBottom: '8px',
						fontSize:'8px'
					}}
				>
					Upgrade to <b>Pro</b> for more resources<Link style={{fontSize:'12px'}}  className="learn-more" to="#">
					(Learn More)
				</Link>
				</Typography>
				
				<div>
					<Button
						variant="contained"
						disableRipple
						sx={{
							width: '100%',
							maxWidth:'150px',
							height: '50px',
							marginTop: '5px',
							marginBottom: '10px',
							borderRadius: '18px',
							backgroundColor:'#bfa600'
						}}
						href="https://adayield.io/"
						target="_blank"
					>
						Upgrade
					</Button>
				</div>
						
			</Box>
			<Grid
				container
				columnSpacing={1}
				justifyContent="center"
				alignContent="center"
				sx={{
					flexDirection: {
						md: 'row',
						xs: 'column',
					},
				}}
			>
				<Grid item>
					<Link className="tos" to="#">
						Terms of Services
					</Link>
				</Grid>
				<Grid item>
					<Link className="tos" to="#">
						Privacy Policy
					</Link>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Upgrade;
