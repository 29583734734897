import { Routes as CommonRoutes, Navigate, Route } from 'react-router-dom';

// ** Import pages
import Index from './pages/index';
import Chat from './pages/chat';
import SignIn from './pages/auth/signin';
import Profile from './pages/profile';
import SignUp from './pages/auth/signup';
import Help from './pages/help';
import Dedicate from './pages/dedicate';

import Layout from './layouts';
import AuthProvider from './layouts/auth-provider';

// ** import type
import type { FC, ReactNode } from 'react';
import VerifyPage from './pages/verifyPage';
import NotVerifyPage from './pages/notverifyPage';

interface MainLayoutProps {
	children?: ReactNode;
}

const Wrapper: FC<MainLayoutProps> = ({ children }) => {
	return (
		<AuthProvider>
			<Layout>{children}</Layout>
		</AuthProvider>
	);
};

const Routes = () => {
	return (
		<CommonRoutes>
			<Route path="/" element={<Index />}></Route>
			<Route path="/signin" element={<SignIn />}></Route>
			<Route path="/signup" element={<SignUp />}></Route>
			<Route
				path="/chat"
				element={
					<Wrapper>
						<Chat />
					</Wrapper>
				}
			></Route>
			<Route
				path="/training_text"
				element={
					<Wrapper>
						<Dedicate />
					</Wrapper>
				}
			></Route>
			<Route
				path="/profile"
				element={
					<Wrapper>
						<Profile />
					</Wrapper>
				}
			></Route>
			<Route
				path="/help"
				element={
					<Wrapper>
						<Help />
					</Wrapper>
				}
			></Route>
			<Route path="/verify/:id/:token" element={<VerifyPage />}></Route>
			<Route
				path="/notverify"
				element={
					<AuthProvider>
						<NotVerifyPage />
					</AuthProvider>
				}
			></Route>
			<Route path="*" element={<Navigate to="/" />} />
		</CommonRoutes>
	);
};
export default Routes;
