export const ADD_MESSAGE = 'ADD_MESSAGE';
export const GET_ANSWER_REQUEST = 'GET_ANSWER_REQUEST';
export const GET_ANSWER_REQUEST_SUCCESS = 'GET_ANSWER_REQUEST_SUCCESS';
export const GET_ANSWER_REQUEST_ERROR = 'GET_ANSWER_REQUEST_ERROR';
export const SHOW_BUY_TOKENS_POPUP='SHOW_BUY_TOKENS_POPUP';
export const HIDE_BUY_TOKENS_POPUP='HIDE_BUY_TOKENS_POPUP';
export const GET_ANSWER_AGAIN_REQUEST = 'GET_ANSWER_AGAIN_REQUEST';

export const POST_SIGNIN_REQUEST = 'POST_SIGNIN_REQUEST';
export const POST_SIGNIN_REQUEST_SUCCESS = 'POST_SIGNIN_REQUEST_SUCCESS';

export const LOGOUT = 'LOGOUT';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_REQUEST_SUCCESS = 'GET_USER_INFO_REQUEST_SUCCESS';

export const GET_CHAT_HISTORY_REQUEST = 'GET_CHAT_HISTORY_REQUEST';
export const GET_CHAT_HISTORY_REQUEST_SUCCESS =
	'GET_CHAT_HISTORY_REQUEST_SUCCESS';

export const DELETE_CHAT_HISTORY_REQUEST = 'DELETE_CHAT_HISTORY_REQUEST';
export const DELETE_CHAT_HISTORY_REQUEST_SUCCESS =
	'DELETE_CHAT_HISTORY_REQUEST_SUCCESS';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_REQUEST_SUCCESS = 'REGISTER_USER_REQUEST_SUCCESS';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_REQUEST_SUCCESS = 'UPDATE_PROFILE_REQUEST_SUCCESS';

export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';

export const GET_MYDOCS_REQUEST = 'GET_MYDOCS_REQUEST';
export const GET_MYDOCS_REQUEST_SUCCESS = 'GET_MYDOCS_REQUEST_SUCCESS';

export const CONVERT_DOCUMENTS_PRIVATE_REQUEST =
	'CONVERT_DOCUMENTS_PRIVATE_REQUEST';
export const DELETE_DOCUMENTS_REQUEST = 'DELETE_DOCUMENTS_REQUEST';

export const GET_PDFS_REQUEST = 'GET_PDFS_REQUEST';
export const GET_PDFS_REQUEST_SUCCESS = 'GET_PDFS_REQUEST_SUCCESS';

export const REGSITER_URL_REQUEST = 'REGSITER_URL_REQUEST';

export const GET_MYWEBSITES_REQUEST = 'GET_MYWEBSITES_REQUEST';
export const GET_MYWEBSITES_REQUEST_SUCCESS = 'GET_MYWEBSITES_REQUEST_SUCCESS';

export const DELETE_WEBSITES_REQUEST = 'DELETE_WEBSITES_REQUEST';

export const GET_WEBSITES_REQUEST = 'GET_WEBSITES_REQUEST';
export const GET_WEBSITES_REQUEST_SUCCESS = 'GET_WEBSITES_REQUEST_SUCCESS';

export const SEND_HELP_EMAIL_REQUEST = 'SEND_HELP_EMAIL_REQUEST';
export const SEND_HELP_EMAIL_REQUEST_SUCCESS =
	'SEND_HELP_EMAIL_REQUEST_SUCCESS';

export const REGISTER_NEWSLETER_REQUEST = 'REGISTER_NEWSLETER_REQUEST';
