import { toast } from 'react-toastify';

export const errorHandler = (error: any) => {
	switch (error.response.status) {
		case 404: {
			toast.error('Not found Page');
			break;
		}
		default: {
			toast.error(
				error?.response?.data?.message
					? error?.response?.data.message
					: error.message
			);
		}
	}
};
