import { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import parse from 'html-react-parser';

// ** Import Type
import { FC } from 'react';

// ** Import MUI
import {
	Grid,
	Typography,
	Box,
	Avatar,
	IconButton,
	Tooltip,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
// import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import { useSelector } from 'react-redux';

interface HumanMessageProps {
	message: string;
}

export const HumanMessage: FC<HumanMessageProps> = ({ message }) => {
	const { user } = useSelector((state: any) => state.auth);
	return (
		<Grid
			container
			justifyContent="end"
			sx={{
				marginTop: '13px',
				marginBottom: '13px',
				paddingLeft: { xs: '10px', md: '90px' },
			}}
		>
			<Grid
				item
				xs={12}
				sx={{ display: 'flex' }}
				justifyContent="end"
				alignItems="end"
			>
				<Box sx={{ marginRight: { xs: '10px', md: '26px' } }}>
					<Typography
						variant="body1"
						sx={{
							backgroundColor: '#bfa600',
							color: '#fff',
							padding: '18px 26px 18px 24px',
							maxWidth: '835px',
							borderRadius: '18px 18px 0px 18px',
							span: {
								whiteSpace: 'inherit !important',
							},
						}}
					>
						{parse(message)}
					</Typography>
					<Box className="triangle-down-human" ></Box>
				</Box>
				<Avatar
					alt="Seeker"
					src={user.avatar ? user.avatar : '/images/seeker.png'}
					sx={{ width: 62, height: 62, display: { xs: 'none', md: 'block' } }}
				/>
			</Grid>
			<Avatar
				alt="Seeker"
				src={user.avatar ? user.avatar : '/images/seeker.png'}
				sx={{
					width: 50,
					height: 50,
					display: { xs: 'block', md: 'none' },
					mt: '10px',
				}}
			/>
		</Grid>
	);
};

interface BotMessageProps {
	message: string;
	refer?: {
		page?: number;
		section?: number;
		name?: string;
		url: string;
	}[];
}

export const BotMessage: FC<BotMessageProps> = ({ message, refer }) => {
	const [clipboardText, setClipboardText] = useState('Click to copy');
	const HandleTooltipClose = () => {
		setTimeout(() => {
			setClipboardText('Click to copy');
		}, 200);
	};
	return (
		<Grid
			container
			justifyContent="start"
			sx={{
				marginTop: '13px',
				marginBottom: '13px',
				paddingRight: { xs: '10px', md: '146px' },
			}}
		>
			<Grid
				item
				xs={12}
				sx={{ display: 'flex', position: 'relative' }}
				alignItems="end"
			>
				<Box sx={{ display: { xs: 'none', md: 'block' } }}>
					<img
						alt="ADYAI"
						src="/images/sec3_img.png"
						style={{ width: 62, height: 62 }}
					/>
				</Box>
				<Box
					sx={{
						marginLeft: { xs: '10px', md: '26px' },
						position: 'relative',
						maxWidth: '100%',
					}}
				>
					<Typography
						component="div"
						variant="body1"
						sx={{
							backgroundColor: 'background.botMessage',
							padding: '18px 38px 18px 26px',
							maxWidth: { md: '835px', xs: '100%' },
							borderRadius: '18px 18px 18px 0px',
							span: {
								whiteSpace: 'inherit !important',
							},
						}}
					>
						{parse(message.replace(/\n/g, '<br>'))}
						{refer && refer.length > 0 ? (
							<>
								<Typography sx={{ color: 'grey' }}>Sources: </Typography>
								{refer.map((item, index) => (
									<Typography
										component="a"
										href={item.url}
										target="_blank"
										sx={{ textDecoration: 'none', color: 'grey' }}
										key={index}
									>
										{item.name ? (
											<Tooltip title={item.name}>
												<Typography
													sx={{ width: '100%', wordWrap: 'break-word' }}
												>
													{item.name.length > 40
														? item.name.slice(0, 40) + '...'
														: item.name}
													{item.page ? ', p. ' + item.page : null}
													{item.section ? ', pos. ' + item.section : null}
												</Typography>
											</Tooltip>
										) : (
											<Tooltip title={item.url}>
												<Typography
													sx={{ width: '100%', wordWrap: 'break-word' }}
												>
													{item.url.length > 40
														? item.url.slice(0, 40) + '...'
														: item.url}
												</Typography>
											</Tooltip>
										)}
									</Typography>
								))}
							</>
						) : null}
					</Typography>
					<Box className="triangle-down-bot"></Box>
					<CopyToClipboard
						text={message.replace(/<[^>]+>/g, '')}
						onCopy={() => {
							// alert("copied");
							setClipboardText('Copied');
						}}
					>
						<Box
							className="clipboard-copy"
							sx={{
								// position: { xs: 'inherit', md: 'absolute' },
								// marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
								position: 'absolute',
								// marginLeft: 0,
							}}
						>
							<Tooltip
								title={clipboardText}
								placement="top"
								onClose={HandleTooltipClose}
							>
								<IconButton aria-label="copy">
									<ContentCopyIcon sx={{ height: '16px', width: '16px' }} />
								</IconButton>
							</Tooltip>
						</Box>
					</CopyToClipboard>
				</Box>
			</Grid>
			<Box
				sx={{
					display: { xs: 'block', md: 'none' },
					mt: '10px',
					width: '50px',
					height: '50px',
				}}
			>
				<img
					alt="ADYAI"
					src="/images/sec3_img.png"
					style={{ width: '100%', height: '100%' }}
				/>
			</Box>
		</Grid>
	);
};

export const BotThinking: FC = () => {
	const [loadingTime, setLoadingTime] = useState(0);
	let timer: any;
	useEffect(() => {
		timer = setInterval(() => {
			setLoadingTime((value) => value + 1);
		}, 1000);
		return () => {
			clearInterval(timer);
		};
	});

	const thinkingContent = useMemo(() => {
		if (loadingTime === 0) {
			return 'Sending your request. Please wait!';
		}  else if (loadingTime >= 3 && loadingTime < 6) {
			return 'Waiting for response ..';
		} else {
			clearInterval(timer);
			return 'Waiting for response ....';
		}
	}, [loadingTime]);

	return (
		<Grid
			container
			justifyContent="start"
			sx={{
				marginTop: '13px',
				marginBottom: '13px',
				paddingRight: { xs: '10px', md: '146px' },
			}}
		>
			<Grid
				item
				xs={12}
				sx={{ display: 'flex', position: 'relative' }}
				alignItems="end"
			>
				<Box sx={{ display: { xs: 'none', md: 'block' } }}>
					<img
						alt="Magic Myc"
						src="/images/sec3_img.png"
						style={{ width: 62, height: 62 }}
					/>
				</Box>
				<Box
					sx={{ marginLeft: { xs: '10px', md: '26px' }, position: 'relative' }}
				>
					<Typography
						variant="body1"
						sx={{
							backgroundColor: 'background.botMessage',
							padding: '18px 38px 18px 26px',
							maxWidth: '835px',
							borderRadius: '18px 18px 18px 0px',
							span: {
								whiteSpace: 'inherit !important',
							},
						}}
					>
						{thinkingContent}
					</Typography>
					<Box className="triangle-down-bot"></Box>
				</Box>
			</Grid>
			<Box
				sx={{
					display: { xs: 'block', md: 'none' },
					mt: '15px',
					width: '50px',
					height: '50px',
				}}
			>
				<img
					alt="Magic Myc"
					src="/images/THINKING-animated.gif"
					style={{ width: '100%', height: '100%' }}
				/>
			</Box>
		</Grid>
	);
};
