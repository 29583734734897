import { makeStyles } from '@mui/styles';
// import { relative } from "path";

const styles = (theme) => {
	return {
		sectionBox: {
			position: 'relative',
			// paddingTop: "5vw",
			// paddingBottom: "5vw",
		},
		shapeBox: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			// display: { xs: "none", md: "flex" },
			// display: "flex",
			flexDirection: 'row',
			zIndex: '0',
		},
		containerGrid: {
			paddingTop: '3vw',
			paddingBottom: '3vw',
			position: 'relative',
			zIndex: '1',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		flexCenter: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		flexBetween: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		image: {
			width: '100%',
			height: '100%',
		},
		header1: {
			fontSize: '4.38vw !important',
			fontWeight: '600 !important',
			// letterSpacing: "-1.33px !important",
			color: '#101010 ! important',
		},
		header2: {
			fontSize: '3.18vw !important',
			fontWeight: '600 !important',
			// letterSpacing: "-1.33px !important",
			color: '#101010 !important',
		},
		header3: {
			fontSize: '2.6vw !important',
			fontWeight: '600 !important',
			// letterSpacing: "-1.33px !important",
			color: '#101010 !important',
		},
		header4: {
			fontSize: '2.08vw !important',
			fontWeight: '600 !important',
			// letterSpacing: "-1.33px !important",
			color: '#101010 !important',
		},
		contentText: {
			fontSize: '1.15vw !important',
			fontWeight: '500 !important',
			color: '#757B8A !important',
		},
		readmoreText: {
			fontSize: '1.2vw !important',
			fontWeight: '500 !important',
			color: '#000080 !important',
		},
		headerLeftButton: {
			color: '#757B8A !important',
			display: 'flex !important',
			// width: " !important",
			padding: '.5vw !important',
		},
		headerRightButton: {
			width: 'auto !important',
			padding: '.5vw !important',
			paddingRight: '2vw !important',
			paddingLeft: '2vw !important',
		},
		footerTitle: {
			fontSize: '1.56vw !important',
			fontWeight: '500 !important',
			color: '#FFFFFF !important',
			paragraph: 'false !important',
		},
		footerContactname: {
			fontSize: '0.94vw !important',
			fontWeight: '700 !important',
			color: '#FFFFFF !important',
			margin: '0 !important',
		},
		footerContactinfo: {
			fontSize: '0.83vw !important',
			fontWeight: '400 !important',
			color: '#FFFFFF !important',
			margin: '0 !important',
		},
		footerContent: {
			fontSize: '1.04vw !important',
			fontWeight: '400 !important',
			color: '#FFFFFF !important',
			opacity: '0.7 !important',
			// margin: '0 !important',
			textDecoration: 'none',
		},
		footerCopyright: {
			fontSize: '1.04vw !important',
			fontWeight: '400 !important',
			color: '#FFFFFF !important',
			margin: '0 !important',
		},
		w_50: {
			width: '50%',
		},
		// buttonText: {
		//   width: "200px",
		//   fontSize: "21.33px !important",
		//   fontWeight: "700 !important",
		//   lineHeight: "32px !important",
		//   textAlign: "center",
		//   color: "#FFFFFF !important",
		//   textTransform: "none",
		// },

		// toolBar: {
		//   height: "10vh",
		//   display: "flex",
		//   justifyContent: "space-between",
		//   padding: "20px",
		//   backgroundColor: "white",
		// },
		// logo: {
		//   color: "blue",
		//   cursor: "pointer",
		// },
		// link: {
		//   color: "#000",
		// },
		// menuIcon: {
		//   color: "#000",
		// },
		// formContainer: {
		//   flexGrow: 1,
		//   padding: "10px",
		//   maxWidth: "700px",
		//   margin: "30px auto",
		//   [theme.breakpoints.between("xs", "sm")]: {
		//     width: "100%",
		//   },
		// },
		// form: {
		//   marginTop: "30px",
		// },
		// formHeading: {
		//   textAlign: "center",
		// },
		// welcomeBox: {
		//   width: "100%",
		//   display: "flex",
		//   minHeight: "600px",
		//   alignItems: "center",
		//   justifyContent: "center",
		// },
		// heroBox: {
		//   width: "100%",
		//   display: "flex",
		//   minHeight: "600px",
		//   alignItems: "center",
		//   justifyContent: "center",
		// },
		// gridContainer: {
		//   display: "flex",
		//   alignItems: "center",
		//   maxWidth: "1300px",
		//   padding: "50px",
		// },
		// aboutUsContainer: {
		//   width: "100%",
		//   display: "flex",
		//   minHeight: "400px",
		//   alignItems: "center",
		//   justifyContent: "center",
		//   margin: "30px 0px 50px 0px",
		// },
		// aboutUsSubtitle: {
		//   opacity: "0.7",
		//   paddingBottom: "30px",
		//   fontSize: "18px",
		// },
		// title: {
		//   paddingBottom: "15px",
		// },
		// subtitle: {
		//   opacity: "0.4",
		//   paddingBottom: "30px",
		// },
		// largeImage: {
		//   width: "100%",
		// },
		// sectionGridContainer: {
		//   display: "flex",
		//   alignItems: "center",
		//   justifyContent: "center",
		//   width: "100%",
		//   minHeight: "500px",
		// },
		// sectionGridItem: {
		//   backgroundColor: "#f2f0f1",
		//   textAlign: "center",
		//   padding: "30px",
		//   width: "200px",
		//   borderRadius: "10px",
		//   margin: "10px !important",
		// },
		// inputField: {
		//   marginBottom: "20px !important",
		// },
		// textArea: {
		//   width: "100%",
		//   marginBottom: "20px",
		//   fontSize: "16px",
		//   padding: "10px",
		// },
		// footerContainer: {
		//   display: "flex",
		//   alignItems: "center",
		//   miHeight: "10vh",
		//   padding: "20px",
		//   justifyContent: "center",
		//   backgroundColor: "#f2f0f1",
		//   flexDirection: "column",
		// },
		// footerText: {
		//   paddingBottom: "10px",
		// },
		// footerDate: {
		//   opacity: "0.4",
		// },
		// testimonialCard: {
		//   backgroundColor: "#fff",
		//   padding: "10px",
		//   minHeight: "200px",
		//   display: "flex",
		//   alignItems: "center",
		// },
		// testimonialStatement: {
		//   paddingBottom: "25px",
		// },
		// avatar: {
		//   marginRight: "10px",
		// },
		// testimonialPosition: {
		//   fontSize: "14px",
		//   opacity: "0.6",
		// },
	};
};

const useStyles = makeStyles(styles);
export default useStyles;
