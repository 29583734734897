import type { MutableRefObject } from "react";
import { forwardRef } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

export const Scrollbar = forwardRef<MutableRefObject<any>, any>(
  (props, ref) => {
    return (
      <SimpleBar
        // @ts-ignore
        ref={ref}
        {...props}
      />
    );
  }
);
