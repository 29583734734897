import React from 'react';
import {
	Grid,
	Typography,
	Box,
	// Link
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';

import useStyles from './styles/styles';

import Shape from './components/Shape';
const Intro = () => {
	// const navigate = useNavigate();
	const classes = useStyles();

	const clases = {
		header2: {
			fontSize: { xs: '7.63vw', md: '3.18vw !important' },
			fontWeight: '600 !important',
			color: '#bfa600 !important',
			mb: { md: '3vw', xs: '0' },
		},
		header3: {
			fontSize: { xs: '7.63vw', md: '2.6vw !important' },
			fontWeight: '600 !important',
			color: '#bfa600 !important',
		},
		contentText: {
			fontSize: { xs: '4.07vw !important', md: '1.15vw !important' },
			fontWeight: '500 !important',
			color: '#fff !important',
			my: { xs: '5vw', md: '0' },
			// textAlign: { xs: 'center', md: 'left' },
		},
		buttonSx: {
			width: { md: '22.48vw', xs: '39.95vw' },
			fontSize: { xs: '3.56vw', md: '1.11vw' },
			paddingY: '2vw',
		},
		readmoreText: {
			fontSize: { xs: '4.07vw !important', md: '1.2vw !important' },
			fontWeight: '500 !important',
			color: '#fff !important',
			textAlign: 'left',
		},
	};
	return (
		<Grid
			container
			className={classes.sectionBox}
			sx={{ background: {
					xs: 'linear-gradient(180deg, #181717 0%, #000 100%)',
					md: 'linear-gradient(180deg, #181717 11.26%, #000 99.96%)',
				} }}
		>
			<Shape secNumber="3" />
			<Grid
				container
				className={classes.containerGrid}
				sx={{ py: { xs: '16.03vw', md: '3vw' } }}
			>
				<Grid item className={classes.flexCenter} xs={11} md={12}>
					<Typography id="how" sx={clases.header2}>
						How ADYAI Works
					</Typography>
				</Grid>
				<Grid
					item
					className={classes.flexCenter}
					sx={{
						display: 'flex',
						flexDirection: { xs: 'column-reverse', md: 'row' },
					}}
				>
					<Grid
						item
						xs={11}
						md={3.5}
						className={classes.flexCenter}
						sx={{ my: { xs: '8.14vw', md: '0' } }}
					>
						<Box
							component="img"
							src="/images/home/sec3_img.png"
							alt="logo"
							className={classes.image}
						/>
					</Grid>
					<Grid item md={0.5}></Grid>
					<Grid item xs={11} md={4.5}>
						<Typography sx={clases.header3}>Introduction</Typography>
						<Box>
							<Typography sx={clases.contentText}>
								Welcome to #ADYAI chat! We're thrilled to have you explore the Cardano ecosystem with our beta AI chatbot.
				
							</Typography>
							<Typography sx={clases.contentText}>
								As we refine and evolve, your feedback will be invaluable. Dive in, discover, and help us shape the future of DeFi interaction. Enjoy your journey with ADYAI!
							</Typography>
						</Box>
						{/* <Link
							component="button"
							variant="body2"
							onClick={() => {
								navigate('/signin');
							}}
							sx={clases.readmoreText}
						>
							Read More {'>'}
						</Link> */}
					</Grid>
					
					
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Intro;
