import React from 'react';
import { Grid, Box } from '@mui/material';
import useStyles from '../styles/styles';

import { YouTube, Facebook, Twitter, Telegram,  } from '@mui/icons-material';

const SocialLink = () => {
	const classes = useStyles();

	return (
		<Grid
			item
			xs={11}
			md={12}
			className={classes.flexCenter}
			sx={{ paddingTop: { xs: '9vw', md: '2vw' } }}
		>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				<a
					href="https://t.me/adayield	"
					target="_blank"
					rel="noreferrer"
				>
					<Box
						component="img"
						src={'/images/home/ic-telegram.png'}
						alt="logo"
						sx={{ height: '100%' }}
					/>
					
				</a>
			</Grid>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				<a
					href="https://twitter.com/realadayield"
					target="_blank"
					rel="noreferrer"
				>
					
					<Box
						component="img"
						src={'/images/home/ic-twitter.png'}
						alt="logo"
						sx={{ height: '100%' }}
					/>
				</a>
			</Grid>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				{/* <InstagramIcon /> */}
				<a
					href="https://www.youtube.com/@adayield"
					target="_blank"
					rel="noreferrer"
				>
					<Box
						component="img"
						src={'/images/home/ic-youtube.png'}
						alt="logo"
						sx={{ height: '100%' }}
					/>
				</a>
			</Grid>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				{/* <PinterestIcon /> */}
				<a
					href="https://discord.gg/fbXT3ywQ3x"
					target="_blank"
					rel="noreferrer"
				>
					{ <Box
						component="img"
						src={'/images/home/ic-discord.png'}
						alt="logo"
						sx={{ height: '100%' }}
					/> }
				</a>
			</Grid>
			<Grid item xs={2} md={2} className={classes.flexCenter}>
				{/* <PinterestIcon /> */}
				<a
					href="https://www.tiktok.com/@adayield"
					target="_blank"
					rel="noreferrer"
				>
					<Box
						component="img"
						src={'/images/home/ic-tiktok.png'}
						alt="logo"
						sx={{ height: '100%' }}
					/> 
				</a>
			</Grid>
			<Grid item xs={4} md={4} className={classes.flexCenter}></Grid>
		</Grid>
	);
};

export default SocialLink;
