import { Box, Typography } from '@mui/material';

import ContentEdit from '../components/chat/content-edit';
import ContentContainer from '../components/chat/content-container';

const Chat = () => {
	return (
		<>
			<Box
				id="scrollableDiv"
				sx={{
					maxHeight: 'calc(100vh - 270px)',
					overflowY: 'scroll',
					display: 'flex',
					flexDirection: 'column-reverse',
					'::-webkit-scrollbar': {
						display: 'none',
					},
				}}
			>
				<ContentContainer />
			</Box>
			<Box
				sx={{
					position: 'fixed',
					bottom: 0,
					minHeight: '165px',
					width: '-webkit-fill-available',
					paddingRight: '40px',
					backgroundColor: '#FFFFFF',
					display: 'flex',
					flexDirection: 'column-reverse',
				}}
			>
				{/*<Box sx={{ padding: '15px 0px 15px 0px' }}>
					<Typography
						variant="body2"
						sx={{
							color: '#8F92A1',
							width: '100%',
							textAlign: 'center',
							display: { xs: 'block', lg: 'none' },
						}}
					>
						short text for small screens
					</Typography>
					<Typography
						variant="body2"
						sx={{
							color: '#8F92A1',
							textAlign: 'center',
							display: { xs: 'none', lg: 'block' },
						}}
					>
						Long text for the large screens
					</Typography>
					</Box>*/}
				<Box>
					<ContentEdit />
				</Box>
			</Box>
		</>
	);
};

export default Chat;
