import { useEffect, useState } from 'react';
import {
	Box,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import SidebarSection, { BootstrapList } from './sidebar-section';
import Upgrade from './upgrade';
// ** Import Icon
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import StorefrontIcon from '@mui/icons-material/Storefront';
// import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';

// ** Import type
import { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Help as HelpIcon } from '@mui/icons-material';
import { sendHelpEmail } from '../../store/auth/actions';

import { toast } from 'react-toastify';

interface Item {
	title: string;
	path?: string;
	permission?: string;
	icon: ReactNode;
}

interface Section {
	key: number;
	title?: string;
	item: Item[];
}

const getSections = (): Section[] => [
	{
		key: 1,
		item: [
			{
				title: 'Chat',
				path: '/chat',
				icon: <ChatBubbleOutlineRoundedIcon />,
			},
			/*{
				title: 'Training Text',
				path: '/training_text',
				// permission: 'pro',
				icon: <UploadFileRoundedIcon />,
			},*/
			// {
			// 	title: 'Help',
			// 	path: '/help',
			// 	icon: <LiveHelpRoundedIcon />,
			// },
		],
	},
	{
		key: 2,
		title: 'Settings',
		item: [
			{
				title: 'Profile',
				path: '/profile',
				icon: <AccountBoxRoundedIcon />,
			},
			{
				title: 'Buy Tokens',
				icon: <StorefrontIcon />,
			},
			{
				title: 'Logout',
				icon: <LogoutRoundedIcon />,
			},
		],
	},
];

interface SideBarProps {
	mode?: string;
}

const SideBar: FC<SideBarProps> = ({ mode }) => {
	const dispatch = useDispatch();

	const sections = getSections();
	const { user } = useSelector((state: any) => state.auth);

	const [buf, setBuf] = useState('');
	useEffect(() => {
		setBuf(
			mode === 'drawer' ? 'calc(100vh - 228px + 66px)' : 'calc(100vh - 228px)'
		);
	}, [mode]);

	const sendEmailCallBack = (status: string) => {
		if (status === 'success') {
			toast.success('Please check email.');
		} else {
			toast.error('One bug happens on server side. Please try again later.');
		}
	};
	const sendHelpEmailFunc = () => {
		dispatch(sendHelpEmail(sendEmailCallBack));
	};

	return (
		<>
			<Box
				sx={{
					background: '#fff',
					border: '1px solid #bfa600',
					borderRadius: '18px',
					minHeight: '100%',
					
					position: 'relative',
					minWidth: { xs: '80vw', md: '350px' },
				}}
			>
				<Box sx={{ textAlign: 'center', paddingTop: '8px' }}>
					<img
						src="/images/logo.png"
						alt="logo"
						style={{ width: '124px', height: '124px' }}
					/>
				</Box>
				<Box
					sx={{
						maxHeight: buf,
						overflowY: 'auto',
						'::-webkit-scrollbar': {
							display: 'none',
						},
					}}
				>
					{sections.map((_item) => {
						return (
							<SidebarSection key={_item.key} item={_item}></SidebarSection>
						);
					})}
					<BootstrapList>
						<ListItem disablePadding sx={{ marginBottom: '13px' }}>
							<ListItemButton onClick={sendHelpEmailFunc}>
								<ListItemIcon>
									<HelpIcon />
								</ListItemIcon>
								<ListItemText primary="Help" />
							</ListItemButton>
						</ListItem>
					</BootstrapList>
					{user.role === 'seeker' && <Upgrade />}
				</Box>
			</Box>
		</>
	);
};

export default SideBar;
