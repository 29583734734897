import React from 'react';
import '../../../assets/main.css';

function YoutubePlayer() {
	const url = `https://www.youtube.com/embed/EkFim_X2e2E?si=Ej4pOZryME8uzQGB`;
	return (
		<iframe
			className="youtube"
			src={url}
			title="ADYAI"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		></iframe>
	);
}

export default YoutubePlayer;
