import { combineReducers } from "redux";

import authReducer from "./auth/reducer";
import msgReducer from "./messages/reducer";
import dataReducer from "./data/reducer";

const reducer = combineReducers({
  msg: msgReducer,
  auth: authReducer,
  data: dataReducer,
});

export default reducer;
