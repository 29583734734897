import { useRef, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

// ** Import Type
import { FC } from 'react';
import { Message } from '../../types/message';

// ** Import Child Components
import { HumanMessage, BotMessage, BotThinking } from './message';
import { getChatHistory, getAnswerAgain } from '../../store/messages/actions';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

const ContentContainer: FC = () => {
	const dispatch = useDispatch();
	const messagesEndRef = useRef<null | HTMLDivElement>(null);
	const { loading, end, messages, answerError } = useSelector(
		(state: any) => state.msg
	);

	const [isMounted, setIsMounted] = useState<boolean>(false);

	const Messages = useMemo(
		() =>
			messages.map((_item: Message, index: number) => {
				if (_item.sender === 'human') {
					return <HumanMessage key={index} message={_item.message} />;
				} else {
					return (
						<BotMessage
							key={index}
							message={_item.message}
							refer={_item.refer}
						/>
					);
				}
			}),
		[messages]
	);

	useEffect(() => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
		setIsMounted(true);
		!messages.length && dispatch(getChatHistory());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, [isMounted, loading]);

	const answerAgainHandler = () => {
		dispatch(getAnswerAgain(messages[0].message));
	};

	return (
		<>
		<InfiniteScroll
			dataLength={messages.length}
			next={() => {
				!end && dispatch(getChatHistory(messages[messages.length - 1].id));
			}}
			style={{ display: 'flex', flexDirection: 'column-reverse' }}
			inverse={true} //
			hasMore={true}
			loader={!end && <h4 style={{ textAlign: 'center' }}>Loading...</h4>}
			scrollableTarget="scrollableDiv"
		>
			<div ref={messagesEndRef}></div>
			{answerError && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<IconButton
						aria-label="Refresh"
						sx={{ border: '2px solid #A2A5C6', mr: '20px' }}
						onClick={answerAgainHandler}
					>
						<RefreshRoundedIcon />
					</IconButton>
					Please request again.
				</Box>
			)}
			{loading && <BotThinking />}
			{Messages}
		</InfiniteScroll>
		
		</>
	);
};

export default ContentContainer;
