import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Divider,
	styled,
} from '@mui/material';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { hideBuyTokensPopup,t_showBuyTokensPopup } from "../../store/messages/actions";
import StoreIcon from '@mui/icons-material/Store';

// ** Import type
import { FC, ReactNode, useState } from 'react';

// ** Import action
import { logout } from '../../store/auth/actions';

// ** define type
interface Item {
	title: string;
	path?: string;
	permission?: string;
	icon: ReactNode;
}

interface Section {
	key: number;
	title?: string;
	item: Item[];
}

interface SideBarSectionProps {
	item: Section;
}

export const BootstrapList = styled(List)(({ theme }) => ({
	'& .Mui-selected': {
		backgroundColor: 'transparent !important',
		border: '4.01px solid #bfa600',
		borderTopStyle: 'none',
		borderRightStyle: 'none',
		borderBottomStyle: 'none',
		'& .MuiListItemIcon-root': {
			color: '#bfa600',
		},
		span: {
			color: '#bfa600',
		},
	},
	'& .MuiListItemIcon-root': {
		color: '#000080',
		minWidth: '50px',
	},
	'& .MuiSvgIcon-root': {
		width: '26.75px',
		height: '26.71px',
	},
	'& .MuiButtonBase-root': {
		paddingLeft: '34.68px',
		paddingTop: '3px',
		paddingBottom: '3px',
		marginTop: '0px',
	},
	'& .MuiButtonBase-root:hover': {
		backgroundColor: 'transparent',
		'& .MuiListItemIcon-root': {
			color: '#bfa600',
		},
		span: {
			color: '#bfa600',
		},
	},
	'& .MuiListSubheader-root': {
		backgroundColor: 'transparent',
		fontFamily: 'DM Sans',
		fontWeight: '700',
		fontStyle: 'normal',
		fontSize: '18px',
		lineHeight: '1.78',
		color: '#000080',
		marginTop: '16px',
	},
	span: {
		fontFamily: 'DM Sans',
		fontWeight: '700',
		fontStyle: 'normal',
		fontSize: '18px',
		lineHeight: 1.78,
		color: '#000080',
	},
}));

const SidebarSection: FC<SideBarSectionProps> = ({ item }) => {

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};


	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const {showBuyTokensPopup}=useSelector((state:any)=>state.msg)

  const handleCloseBuyTokensModal = () => {
    // Dispatch the action to hide the pop-up
    dispatch(hideBuyTokensPopup());
  };
  const handleOpenBuyTokensModal = () => {
    // Dispatch the action to hide the pop-up
    dispatch(t_showBuyTokensPopup());
  };

	const { user } = useSelector((state: any) => state.auth);

	const onClickHandler = (path: string | undefined) => {
		if (typeof path === 'string') {
			return () => {
				navigate(path);
			};
		} else {
			return () => {
				// alert("here");
				dispatch(logout());
			};
		}
	};

	const [selectedTokens, setselectedTokens] = useState('');

	// Add this function to handle "Buy Tokens" click
	const handleBuyTokensClick = () => {
		// Call your function or open the modal here
		// Example: openBuyTokensModal();
		console.log('Buy Tokens clicked');
	};

	return (
		<>
			<Divider color='#bfa600' />
			<nav aria-label="main mailbox folders">
				<Modal
					open={showBuyTokensPopup}
					onClose={handleCloseBuyTokensModal}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Buy Tokens
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							<p>Select a number of tokens you want to buy ?</p>
							<Stack direction="row" spacing={10}>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={selectedTokens}
									onChange={(event) => { setselectedTokens(event.target.value); }}
									defaultValue={'10,000'}
								>
									<MenuItem value={10000}>10,000</MenuItem>
									<MenuItem value={50000}>50,000</MenuItem>
									<MenuItem value={100000}>100,000</MenuItem>
									<MenuItem value={250000}>250,000</MenuItem>
									<MenuItem value={500000}>500,000</MenuItem>
									<MenuItem value={1000000}>1,000,000</MenuItem>
								</Select>
								<Button variant="contained" endIcon={<StoreIcon />}>
									Buy
								</Button>
							</Stack>
						</Typography>
					</Box>
				</Modal>
				<BootstrapList
					subheader={
						item.title ? <ListSubheader>{item.title}</ListSubheader> : null
					}
				>
					{item?.item?.map((_item) => {
						// if (user.role === "user" && !_item.permission) {
						if (
							_item.permission !== 'pro' ||
							user.role === _item.permission ||
							user.role === 'admin'
						) {
							return (
								<ListItem
									key={_item.title}
									disablePadding
									sx={{ marginBottom: '13px' }}
								>
									<ListItemButton
										selected={location.pathname === _item.path}
										onClick={
											_item.title === 'Buy Tokens' ? handleOpenBuyTokensModal : onClickHandler(_item.path)
										}
									>
										<ListItemIcon>{_item.icon}</ListItemIcon>
										<ListItemText primary={_item.title} />
									</ListItemButton>
								</ListItem>
							);
						} else {
							return null;
						}
					})}
				</BootstrapList>
			</nav>
		</>
	);
};

export default SidebarSection;
