import * as React from 'react';
// import SwipeableViews from 'react-swipeable-views';
import { Tabs, Tab, Divider, Box, Typography } from '@mui/material';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';

// ** import children component
import {
	DedicatePdf,
	DedicateWebsite,
	MyDocs,
	MyWebsites,
} from '../components/dedicate';
import { useSelector } from 'react-redux';

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

const Dedicate = () => {
	const { user } = useSelector((state: any) => state.auth);
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	// const handleChangeIndex = (index: number) => {
	// 	setValue(index);
	// };

	return (
		user && (
			<Box
				sx={{
					maxHeight: 'calc(100vh - 140px)',
					overflowY: 'scroll',
					'::-webkit-scrollbar': {
						display: 'none',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: { sm: 'center', xs: 'start' },
						flexDirection: {
							sm: 'row',
							xs: 'column',
						},
						mt: '15px',
					}}
				>
					<Typography
						component="h1"
						sx={{
							fontSize: '30px !important',
							lineHeight: '23px',
							color: 'primary.main',
							fontWeight: '700',
							fontStyle: 'normal',
							fontFamily: 'DM Sans',
							mr: 'auto',
						}}
					>
						Training Text
					</Typography>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="icon position tabs example"
						variant="scrollable"
						scrollButtons="auto"
						sx={{ width: { xs: '100%', sm: 'auto' } }}
					>
						<Tab
							icon={<PictureAsPdfRoundedIcon />}
							iconPosition="start"
							label="Docs"
						/>
						<Tab
							icon={<PictureAsPdfRoundedIcon />}
							iconPosition="start"
							label="Websites"
						/>
						{user.role !== 'seeker' && (
							<Tab
								label="My Docs"
								iconPosition="start"
								icon={<PictureAsPdfRoundedIcon />}
							/>
						)}
						{user.role !== 'seeker' && (
							<Tab
								label="My Websites"
								iconPosition="start"
								icon={<PictureAsPdfRoundedIcon />}
							/>
						)}
					</Tabs>
				</Box>
				<Divider />
				{/* <SwipeableViews
					axis={'x'}
					index={value}
					onChangeIndex={handleChangeIndex}
				> */}
				<TabPanel value={value} index={0} dir={'ltr'}>
					<DedicatePdf />
				</TabPanel>
				<TabPanel value={value} index={1} dir={'ltr'}>
					<DedicateWebsite />
				</TabPanel>
				{user.role !== 'seeker' && (
					<TabPanel value={value} index={2} dir="1tr">
						<MyDocs />
					</TabPanel>
				)}
				{user.role !== 'seeker' && (
					<TabPanel value={value} index={3} dir="1tr">
						<MyWebsites />
					</TabPanel>
				)}
				{/* </SwipeableViews> */}
			</Box>
		)
	);
};

export default Dedicate;
