import React from 'react';

import { Button, Box } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import apiClient from '../store/apiClient';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function VerifyPage() {
	const { user } = useSelector((state: any) => state.auth);
	const [sending, setSending] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertStatus, setAlertStatus] = useState<AlertColor>('success');
	const [message, setMessage] = useState('Sent. Please check email.');

	const sendEmail = () => {
		apiClient
			.post(`/user/${user.id}/verifyemail`)
			.then(() => {
				setAlertStatus('success');
				setAlertOpen(true);
				setMessage('Sent. Please check email.');
			})
			.catch(() => {
				setAlertStatus('error');
				setMessage('Oops! Sorry. Server has one problem. Please try again.');
				setAlertOpen(true);
			})
			.finally(() => {
				setSending(false);
			});
	};

	const handleClose = () => {
		setAlertOpen(false);
	};

	return (
		<Box sx={{ background: 'white', width: '100%' }}>
			<Box
				sx={{
					margin: 'auto',
					marginTop: '100px',
					minWidth: '400px',
					maxWidth: '800px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Link to="/">
					<Box
						component="img"
						src="/images/logo.png"
						sx={{ width: '100px' }}
					/>
				</Link>
				<Box>Please check your email to verify.</Box>
				<Box sx={{ display: 'flex' }}>
					If you didn't recieve verification email, please click resend button
				</Box>
				<Button onClick={sendEmail} sx={{ marginLeft: '20px' }}>
					{sending ? 'sending' : 'resend'}
				</Button>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={alertOpen}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					severity={alertStatus || 'success'}
					sx={{ width: '100%' }}
				>
					{message}
				</Alert>
			</Snackbar>
		</Box>
	);
}

export default VerifyPage;
