import * as React from 'react';
import { useState } from 'react';
import type { ChangeEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alpha } from '@mui/material/styles';
import {
	Alert,
	AlertColor,
	AlertTitle,
	Button,
	Input,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Tooltip,
	Typography,
	Paper,
	Checkbox,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Lock as LockIcon,
	LockOpen as LockOpenIcon,
} from '@mui/icons-material';
import { visuallyHidden } from '@mui/utils';
import { grey } from '@mui/material/colors';
import { Upload } from '@mui/icons-material';

import {
	getMyDocs,
	handleUploadFiles,
	convertDocsPrivate,
	deleteDocs,
} from '../../store/data/actions';
import moment from 'moment';
import { toast } from 'react-toastify';

interface Data {
	id: string;
	name: string;
	url: string;
	status: string;
	privateStatus: string;
	updated_at: string;
}

function createData({
	id,
	name,
	url,
	status,
	privateSatus,
	updated_at,
}: {
	id: number;
	name: string;
	url: string;
	status: string;
	privateSatus: boolean;
	updated_at: string;
}): Data {
	return {
		id: id.toString(),
		name,
		url,
		status,
		privateStatus: privateSatus === true ? 'private' : 'public',
		updated_at: moment(updated_at).format('MM/DD/YYYY'),
	};
}

type Order = 'asc' | 'desc';

type StatusFilter = 'done' | 'ready' | 'pending' | 'failed' | 'all';

interface StatusFilterOption {
	label: string;
	value: StatusFilter;
}
const filterOptions: StatusFilterOption[] = [
	{
		label: 'All',
		value: 'all',
	},
	{
		label: 'Ready',
		value: 'ready',
	},
	{
		label: 'Pending',
		value: 'pending',
	},
	{
		label: 'Failed',
		value: 'failed',
	},
	{
		label: 'Done',
		value: 'done',
	},
];

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'name',
		numeric: false,
		disablePadding: true,
		label: 'File Name',
	},
	{
		id: 'status',
		numeric: true,
		disablePadding: false,
		label: 'Status',
	},
	{
		id: 'privateStatus',
		numeric: true,
		disablePadding: false,
		label: 'Private',
	},
	{
		id: 'updated_at',
		numeric: true,
		disablePadding: false,
		label: 'Updated At',
	},
];

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
	} = props;
	const createSortHandler =
		(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts',
						}}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

interface EnhancedTableToolbarProps {
	numSelected: number;
	convertFunc: any;
	deleteFunc: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
	const { numSelected, convertFunc, deleteFunc } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant="h6"
					id="tableTitle"
					component="div"
				>
					Documents
				</Typography>
			)}
			{numSelected > 0 ? (
				<Box sx={{ display: 'flex', gap: 1 }}>
					<Tooltip title="Convert Private">
						<IconButton onClick={() => convertFunc(true)}>
							<LockIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Convert Public">
						<IconButton onClick={() => convertFunc(false)}>
							<LockOpenIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete">
						<IconButton onClick={deleteFunc}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</Box>
			) : null}
		</Toolbar>
	);
}

export default function EnhancedTable() {
	const dispatch = useDispatch();

	const { myDocs } = useSelector((state: any) => state?.data);
	const queryRef = React.useRef<HTMLInputElement | null>(null);

	const [total, setTotal] = useState<number>(0);
	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<keyof Data>('updated_at');
	const [selected, setSelected] = React.useState<readonly string[]>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const [uploadFiles, setUploadFiles] = useState<FileList | null>();
	const [alertOpen, setAlertOpen] = useState<boolean>(false);
	const [alertContent, setAlertContent] = useState<string>('');
	const [alertStatus, setAlertStatus] = useState<AlertColor | undefined>(
		'success'
	);

	// const [statusFilter, setStatusFilter] = useState<string>('all');
	const [filter, setFilter] = useState<{
		query: string | undefined;
		statusFilter: string | undefined;
	}>({
		query: '',
		statusFilter: 'all',
	});

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = visibleRows.map((n: Data) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	const visibleRows = React.useMemo(() => {
		setTotal(myDocs.total);
		return myDocs.data.map(
			(doc: {
				id: number;
				name: string;
				url: string;
				status: string;
				private: boolean;
				updated_at: string;
			}) => createData({ ...doc, privateSatus: doc.private })
		);
	}, [myDocs]);

	const applyPagination = React.useCallback(() => {
		dispatch(getMyDocs({ order, orderBy, page, rowsPerPage, filter }));
	}, [dispatch, order, orderBy, page, rowsPerPage, filter]);

	React.useEffect(() => {
		setSelected([]);
		applyPagination();
	}, [order, orderBy, page, rowsPerPage, filter, applyPagination]);

	const onChangeUploadFiles = (e: ChangeEvent<HTMLInputElement>): void => {
		setUploadFiles(e.target.files);
	};

	const onUploadCallBack = ({
		status,
		returnData,
	}: {
		status: AlertColor | undefined;
		returnData: any;
	}) => {
		setAlertStatus(status);
		if (status === 'success') {
			setAlertContent(returnData.data.message);
			setUploadFiles(null);
		} else {
			setAlertContent(returnData.message);
		}
		setAlertOpen(true);
		applyPagination();
	};

	const onSubmit = () => {
		if (uploadFiles?.length) {
			const data = new FormData();
			for (let i = 0; i < uploadFiles.length; i++) {
				const file = uploadFiles[i];
				data.append('books', file);
			}
			dispatch(handleUploadFiles(data, onUploadCallBack));
		} else {
			return false;
		}
	};

	const handleAlertClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}

		setAlertOpen(false);
	};

	const handleQueryChange = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		// setStatusFilter((prevState) => ({
		// 	...prevState,
		// 	query: queryRef.current?.value,
		// }));
		setFilter((prevState) => {
			return { ...prevState, query: queryRef.current?.value };
		});
		setPage(0);
	};

	const handleFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
		// setStatusFilter(event.target.value as StatusFilter);
		setFilter((state) => {
			return { ...state, statusFilter: event.target.value };
		});
		setPage(0);
	};

	const callBackFunc = (data: { status: string; returnData: any }) => {
		if (data.status === 'success') {
			console.log(data);
			toast.success(data.returnData.data.message);
			setSelected([]);
			applyPagination();
		} else {
			toast.error(data.returnData.message);
		}
	};
	const bulkConvertPrivateFunc = (convertValue: boolean) => {
		dispatch(convertDocsPrivate(selected, convertValue, callBackFunc));
	};

	const bulkDeleteFunc = () => {
		dispatch(deleteDocs(selected, callBackFunc));
	};

	return (
		<Box sx={{ marginX: '-24px' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				{alertOpen && (
					<Alert
						onClose={handleAlertClose}
						severity={alertStatus}
						sx={{ width: '100%' }}
					>
						<AlertTitle>
							{alertStatus &&
								alertStatus[0].toUpperCase() + alertStatus.slice(1)}
						</AlertTitle>
						{alertContent}
					</Alert>
				)}
				<Box
					sx={{
						display: 'flex',
						p: 3,
						flexDirection: { sm: 'row', xs: 'column' },
						alignItems: 'center',
						gap: 3,
					}}
				>
					<Box sx={{ width: '100%', height: '55px' }}>
						<label
							htmlFor="documents-upload"
							style={{ width: 'auto', height: '55px' }}
						>
							<Input
								sx={{ display: 'none' }}
								id="documents-upload"
								inputProps={{ multiple: true, accept: '.pdf,.doc,.docx,.txt' }}
								type="file"
								onChange={onChangeUploadFiles}
							/>
							<Box
								sx={{
									border: 'solid 1px',
									borderColor: grey[400],
									height: '55px',
									':hover': { borderColor: grey[600] },
									borderRadius: '10px',
									display: 'flex',
									alignItems: 'center',
									padding: '10px 20px',
									gap: 4,
								}}
							>
								<div>
									{uploadFiles?.length ? (
										uploadFiles.length + ' files'
									) : (
										<Typography fontSize={24} color={grey[400]}>
											Please select files
										</Typography>
									)}
								</div>
							</Box>
						</label>
					</Box>
					<Button
						sx={{
							paddingX: '20px',
							minWidth: '150px',
							borderRadius: '10px',
							height: '55px',
							width: {
								sm: 'auto',
								xs: '100%',
							},
						}}
						startIcon={<Upload />}
						variant="outlined"
						disabled={uploadFiles?.length ? false : true}
						onClick={onSubmit}
					>
						upload
					</Button>
				</Box>

				<Box
					sx={{
						pt: 0,
						p: 3,
						display: 'flex',
						flexDirection: { sm: 'row', xs: 'column' },
						gap: 3,
					}}
				>
					<Box
						component="form"
						onSubmit={handleQueryChange}
						sx={{ width: '100%' }}
					>
						<TextField
							defaultValue=""
							fullWidth
							inputProps={{ ref: queryRef }}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchRoundedIcon />
									</InputAdornment>
								),
							}}
							placeholder="Search Documents"
						/>
					</Box>
					<TextField
						label="Filter By"
						name="filter"
						onChange={handleFilterChange}
						select
						SelectProps={{ native: true }}
						value={filter?.statusFilter}
						sx={{ minWidth: '150px' }}
					>
						{filterOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</TextField>
				</Box>

				<EnhancedTableToolbar
					numSelected={selected.length}
					convertFunc={bulkConvertPrivateFunc}
					deleteFunc={bulkDeleteFunc}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby="tableTitle"
						size={'medium'}
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={visibleRows.length}
						/>
						<TableBody>
							{visibleRows.map((row: Data, index: any) => {
								const isItemSelected = isSelected(row.id);
								const labelId = `enhanced-table-checkbox-${index}`;

								return (
									<TableRow
										hover
										onClick={(event) => handleClick(event, row.id)}
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.id}
										selected={isItemSelected}
										sx={{ cursor: 'pointer' }}
									>
										<TableCell padding="checkbox">
											<Checkbox
												color="primary"
												checked={isItemSelected}
												inputProps={{
													'aria-labelledby': labelId,
												}}
											/>
										</TableCell>
										<TableCell
											component="th"
											id={labelId}
											scope="row"
											padding="none"
										>
											<Tooltip title={row?.name}>
												<Typography
													component="div"
													width="400px"
													sx={{
														textOverflow: 'ellipsis',
														overflow: 'hidden',
														whiteSpace: 'nowrap',
													}}
												>
													<Typography
														component="a"
														sx={{
															textDecoration: 'none',
															color: grey[900],
														}}
														variant="subtitle1"
														href={row?.url}
														target="_blank"
													>
														{row?.name}
													</Typography>
												</Typography>
											</Tooltip>
										</TableCell>
										<TableCell align="right">{row.status}</TableCell>
										<TableCell align="right">{row.privateStatus}</TableCell>
										<TableCell align="right">{row.updated_at}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={total}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			{/* <Snackbar
				open={alertOpen}
				autoHideDuration={10000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleAlertClose}
					severity={alertStatus}
					sx={{ width: '100%' }}
				>
					<AlertTitle>
						{alertStatus && alertStatus[0].toUpperCase() + alertStatus.slice(1)}
					</AlertTitle>
					{alertContent}
				</Alert>
			</Snackbar> */}
		</Box>
	);
}
