export const decode = (token: any): any => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [encodedHeader, encodedPayload, signature] = token.split(".");
  const payload = JSON.parse(atob(encodedPayload));

  return payload.data;
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem("accessToken");
};
