import { call, all, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../constants';

import apiClient from '../apiClient';

import { getAccessToken } from '../../utils/jwt';

import { ServerResponse } from 'node:http';

async function shooterSignIn(userInfo: any) {
	return apiClient.post('/login', userInfo);
}

function* signIn(): any {
	return yield takeLatest(
		actionTypes.POST_SIGNIN_REQUEST,
		function* (action: any) {
			try {
				const data: ServerResponse = yield call(shooterSignIn, action.payload);
				yield put({
					type: actionTypes.POST_SIGNIN_REQUEST_SUCCESS,
					payload: {
						...data,
					},
				});
				action.navigate('/chat');
			} catch (error: any) {
				console.log(error);
				action.helpers.setStatus({ success: false });
				action.helpers.setErrors({
					submit: error?.response?.data?.message
						? error?.response?.data?.message
						: error?.message,
				});
				action.helpers.setSubmitting(false);
			}
		}
	);
}

async function shooterGetUserInfo(accessToken: any) {
	return apiClient.get('/user/verify', {
		headers: { Authorization: accessToken },
	});
}

function* getUserInfo(): any {
	return yield takeLatest(
		actionTypes.GET_USER_INFO_REQUEST,
		function* (action: any) {
			try {
				const data: ServerResponse = yield call(
					shooterGetUserInfo,
					action.payload
				);
				yield put({
					type: actionTypes.GET_USER_INFO_REQUEST_SUCCESS,
					payload: {
						...data,
					},
				});
			} catch (error) {
				console.log(error);
				action.navigate && action.navigate('/signin');
			}
		}
	);
}

async function shooterRegisterUser(userInfo: any) {
	return apiClient.post('/user', {
		firstName: userInfo.firstName,
		phoneNumber: userInfo.phoneNumber,
		email: userInfo.email,
		password: userInfo.password,
		confirmPassword: userInfo.passwordConfirm,
	});
}

function* registerUser(): any {
	return yield takeLatest(
		actionTypes.REGISTER_USER_REQUEST,
		function* (action: any) {
			try {
				const data: ServerResponse = yield call(
					shooterRegisterUser,
					action.payload
				);
				console.log(data);
				yield put({
					type: actionTypes.REGISTER_USER_REQUEST_SUCCESS,
					payload: {
						...data,
					},
				});
				action.navigate('/chat');
			} catch (error: any) {
				console.log(error);
				action.helpers.setStatus({ success: false });
				action.helpers.setErrors({
					submit: error?.response?.data?.message
						? error?.response?.data?.message
						: error?.message,
				});
				action.helpers.setSubmitting(false);
			}
		}
	);
}

async function shooterUpdateProfile(userInfo: any) {
	return apiClient.put('/user', userInfo, {
		headers: { Authorization: getAccessToken() },
	});
}

function* updateProfile(): any {
	return yield takeLatest(
		actionTypes.UPDATE_PROFILE_REQUEST,
		function* (action: any) {
			try {
				yield call(shooterUpdateProfile, action.payload);
				action.setLoading(false);
				yield put({
					type: actionTypes.GET_USER_INFO_REQUEST,
					payload: getAccessToken(),
				});
			} catch (error: any) {
				console.log(error);
				action.helpers.setStatus({ success: false });
				action.helpers.setErrors({
					submit: error?.response?.data?.message
						? error?.response?.data?.message
						: error?.message,
				});
				action.helpers.setSubmitting(false);
			}
		}
	);
}

function shooterSendHelpEmail() {
	return apiClient.post('/user/sendhelpemail', null, {
		headers: { Authorization: getAccessToken() },
	});
}

function* sendHelpEmail(): any {
	return yield takeLatest(
		actionTypes.SEND_HELP_EMAIL_REQUEST,
		function* (action: any) {
			try {
				yield call(shooterSendHelpEmail);
				action.callBack('success');
			} catch (error) {
				console.log(error);
				action.callBack('error');
			}
		}
	);
}

function shooterRegisterNewsletter(email: string) {
	return apiClient.post(
		'/user/newsletter',
		{ email },
		{
			headers: { Authorization: getAccessToken() },
		}
	);
}

function* registerNewsletter(): any {
	return yield takeLatest(
		actionTypes.REGISTER_NEWSLETER_REQUEST,
		function* (action: any) {
			try {
				const data: ServerResponse = yield call(
					shooterRegisterNewsletter,
					action.payload
				);
				action.callBack({ status: 'success', returnData: data });
			} catch (error: any) {
				action.callBack({
					status: 'error',
					returnData: error,
				});
				return false;
			}
		}
	);
}

export default function* runBlockingCallsExample() {
	yield all([
		signIn(),
		getUserInfo(),
		registerUser(),
		updateProfile(),
		sendHelpEmail(),
		registerNewsletter(),
	]);
}
