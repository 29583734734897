import * as actionTypes from '../constants';

export function getPDFs(filter: any): {
	type: string;
	payload: any;
} {
	return {
		type: actionTypes.GET_PDFS_REQUEST,
		payload: filter,
	};
}

export function getMyDocs(filter: any): {
	type: string;
	payload: any;
} {
	return {
		type: actionTypes.GET_MYDOCS_REQUEST,
		payload: filter,
	};
}

export function handleUploadFiles(
	files: any,
	callBack: any
): {
	type: string;
	payload: any;
	callBack: any;
} {
	return {
		type: actionTypes.UPLOAD_FILES_REQUEST,
		payload: files,
		callBack,
	};
}

export function convertDocsPrivate(
	items: readonly string[],
	convertPrivate: boolean,
	callBack: any
): {
	type: string;
	payload: any;
	callBack: any;
} {
	return {
		type: actionTypes.CONVERT_DOCUMENTS_PRIVATE_REQUEST,
		payload: { items, convertPrivate },
		callBack,
	};
}

export function deleteDocs(
	items: readonly string[],
	callBack: any
): {
	type: string;
	payload: any;
	callBack: any;
} {
	return {
		type: actionTypes.DELETE_DOCUMENTS_REQUEST,
		payload: { items },
		callBack,
	};
}

export function getWebsites(filter: any): {
	type: string;
	payload: any;
} {
	return {
		type: actionTypes.GET_WEBSITES_REQUEST,
		payload: filter,
	};
}

export function getMyWebsites(filter: any): {
	type: string;
	payload: any;
} {
	return {
		type: actionTypes.GET_MYWEBSITES_REQUEST,
		payload: filter,
	};
}

export function registerWebsiteUrl(
	url: string,
	callBack: any
): {
	type: string;
	payload: any;
	callBack: any;
} {
	return {
		type: actionTypes.REGSITER_URL_REQUEST,
		payload: url,
		callBack,
	};
}

export function deleteWebsites(
	items: readonly string[],
	callBack: any
): {
	type: string;
	payload: any;
	callBack: any;
} {
	return {
		type: actionTypes.DELETE_WEBSITES_REQUEST,
		payload: { items },
		callBack,
	};
}
