import React from 'react';
import {BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import Routes from './Routes';
import store from './store';
import { createTheme } from './theme';

import 'react-toastify/dist/ReactToastify.css';
import { MeshProvider } from '@meshsdk/react';

const theme = createTheme({
	direction: 'ltr',
	responsiveFontSizes: true,
	mode: 'light',
});

function App() {
	return (
		<MeshProvider>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router>
					<Routes />
				</Router>
				<ToastContainer />
			</ThemeProvider>
		</Provider>
		</MeshProvider>
	);
}

export default App;
