import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { Grid } from '@mui/material';

import useStyles from './styles/styles';

const pages = [
	{ name: 'Home', link: '#home' },
	{ name: 'About ADYAI', link: '#about' },
	{ name: 'How It Works', link: '#how' },
	{ name: 'Contact Us', link: '#contact' },
];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Header() {
	const classes = useStyles();
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
		null
	);
	// const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
	// 	null
	// );

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	// const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
	// 	setAnchorElUser(event.currentTarget);
	// };

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	// const handleCloseUserMenu = () => {
	// 	setAnchorElUser(null);
	// };

	return (
		<AppBar
			position="relative"
			color="secondary"
			sx={{
				boxShadow: '0px 10px 81px rgba(0, 0, 0, 0.09)',
				paddingX: { md: '8.33vw', xs: '4.07vw' },
				height: { xs: '25.45vw', md: '6.25vw' },
			}}
			className={classes.flexCenter}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<Box
					component="img"
					src="images/logo.png"
					alt="logo"
					sx={{
						width: '6.25vw',
						display: { xs: 'none', md: 'flex' },
					}}
				/>
				<Grid
					item
					xs={2}
					className={classes.flexCenter}
					// sx={{ width: '20%' }}
				>
					<Box
						component="img"
						src="images/logo.png"
						alt="logo"
						sx={{
							width: '15.78vw',
							padding: '0.5vw',
							display: { xs: 'flex', md: 'none' },
						}}
					/>
				</Grid>
				<Grid
					item
					xs={5}
					// sx={{ width: '40%' }}
				>
					<Box
						sx={{
							// flexGrow: 1,
							display: { xs: 'flex', md: 'none' },
						}}
					>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<Box
								component="img"
								src="images/home/nav_menu.png"
								alt="logo"
								sx={{
									width: '13.49vw',

									display: { xs: 'flex', md: 'none' },
								}}
							/>
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{pages.map(({ name, link }) => (
								<a
									href={link}
									key={name}
									style={{ textDecoration: 'none', color: 'MenuText' }}
								>
									<MenuItem onClick={handleCloseNavMenu}>
										<Typography textAlign="center">{name}</Typography>
									</MenuItem>
								</a>
							))}
							<a
								href="/signup"
								style={{ textDecoration: 'none', color: 'MenuText' }}
							>
								<MenuItem key={1}>
									<Typography textAlign="center">Register</Typography>
								</MenuItem>
							</a>
							<a
								href="/signin"
								style={{ textDecoration: 'none', color: 'MenuText' }}
							>
								<MenuItem key={2}>
									<Typography textAlign="center">Login</Typography>
								</MenuItem>
							</a>
							<a
								href="https://adayield.io"
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'none', color: 'MenuText' }}
							>
								<MenuItem key={3} onClick={handleCloseNavMenu}>
									<Typography textAlign="center">Donate</Typography>
								</MenuItem>
							</a>
						</Menu>
					</Box>
				</Grid>
				<Box
					sx={{
						flexGrow: 1,
						// gap: '1vw',
						display: { xs: 'none', md: 'flex' },
						justifyContent: 'left',
					}}
				>
					{pages.map(({ name, link }) => (
						<a href={link} key={name} style={{ textDecoration: 'none' }}>
							<Button
								onClick={handleCloseNavMenu}
								className={classes.headerLeftButton}
							>
								{name}
							</Button>
						</a>
					))}
				</Box>
				<Grid
					item
					md={5}
					// className={classes.flexCenter}
					sx={{
						justifyContent: 'end',
						alignContent: 'center',
						display: { xs: 'none', md: 'flex' },
					}}

					// sx={{ width: '40%' }}
				>
					<Box sx={{ display: 'flex', gap: '1vw' }}>
						<Button
							variant="text"
							className={classes.headerRightButton}
							sx={{ color: '#000080' }}
							href="/signup"
						>
							Register
						</Button>
						<Button
							variant="outlined"
							href="/signin"
							className={classes.headerRightButton}
							sx={{ color: '#000080' }}
						>
							Login
						</Button>
						<Button
							variant="contained"
							className={classes.headerRightButton}
							href="https://adayield.io"
							target="_blank"
						>
							Donate
						</Button>
					</Box>
				</Grid>
			</Box>
			{/* </Container> */}
		</AppBar>
	);
}
export default Header;
