import { useRef, useEffect, useState, useCallback } from "react";
import ContentEditable from "react-contenteditable";
import { useDispatch } from "react-redux";

// ** Import MUI
import { IconButton, Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

// ** Import Type
import { FC } from "react";

// ** Import action
import { getAnswer, deleteChatHistory } from "../../store/messages/actions";
import { BorderAll } from "@mui/icons-material";

const ContentEdit: FC = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const inputBoxRef = useRef(null);

  const deleteHandler = () => {
    dispatch(deleteChatHistory());
  };

  const handleChange = (evt: any) => {
    const value = evt.target.value;
    setText(value);
  };

  const onSubmit = useCallback(() => {
    if (text !== "") {
      dispatch(getAnswer(text));
      setText("");
    }
  }, [text, dispatch]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand("insertLineBreak");
        } else {
          onSubmit();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onSubmit]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <IconButton
        aria-label="Refresh"
        sx={{ border: "2px solid #bfa600  ", mr: "20px",color:'#bfa600' }}
        onClick={deleteHandler}
        
      >
        <RefreshRoundedIcon />
      </IconButton>
      <ContentEditable
      style={{border:'2px solid #bfa600'}}
        className="content-editable"
        data-text="Send a message"
        innerRef={inputBoxRef}
        html={text}
        onChange={handleChange}
      />
      <IconButton
        sx={{ position: "absolute", right: "5px", color:'#bfa600', border:"2px solid #bfa600" }}
        aria-label="Submit"
        onClick={onSubmit}
      >
        <SendIcon />
      </IconButton>
    </Box>
  );
};

export default ContentEdit;
