import React, { FC } from 'react';
import { Box } from '@mui/material';
import useStyles from '../styles/styles';

interface SecProps {
	secNumber: string;
	justifyContent?: string;
}
const Shape: FC<SecProps> = ({ secNumber, justifyContent }) => {
	const classes = useStyles();

	const name =
		'/images/home/sec' +
		secNumber +
		'_shape' +
		(justifyContent === 'end' ? 2 : 1) +
		'.png';
	return (
		<>
			<Box
				className={classes.shapeBox}
				sx={{
					display: { xs: 'none', md: 'flex' },
					justifyContent: 'space-between',
				}}
			>
				<Box
					component="img"
					src={'/images/home/sec' + secNumber + '_shape1.png'}
					alt="logo"
				/>
				<Box
					component="img"
					src={'/images/home/sec' + secNumber + '_shape2.png'}
					alt="logo"
				/>
			</Box>
			{justifyContent && (
				<Box
					className={classes.shapeBox}
					sx={{
						display: { xs: 'flex', md: 'none' },
						justifyContent: { justifyContent },
					}}
				>
					<Box component="img" src={name} alt="logo" />
				</Box>
			)}
		</>
	);
};

export default Shape;
