import moment from 'moment';
// ** import MUI
import {
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	TablePagination,
	Tooltip,
	Typography,
} from '@mui/material';

import { Scrollbar } from '../scrollbar';

// ** import type
import type { FC, MouseEvent, ChangeEvent } from 'react';
import type { WebsiteData } from '../../types/pdf-data';
import { grey } from '@mui/material/colors';

interface DedicateWebsitesType {
	data: WebsiteData[];
	onPageChange: (
		event: MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => void;
	onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	page: number;
	rowsPerPage: number;
	total: number;
}

const DedicateWebsites: FC<DedicateWebsitesType> = ({
	data,
	onPageChange,
	onRowsPerPageChange,
	page,
	rowsPerPage,
	total,
}) => {
	return (
		<>
			<TablePagination
				component="div"
				count={total}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[50, 100, 150, 200]}
			/>
			<Scrollbar>
				<Table sx={{ minWidth: '700px' }}>
					<TableHead
					// sx={{ visibility: enableBulkActions ? "collapse" : "visible" }}
					>
						<TableRow>
							<TableCell>Website URL</TableCell>
							<TableCell>Registered by User</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Updated At</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((_item) => (
							<TableRow hover key={_item?.id}>
								<TableCell sx={{ width: '43%' }}>
									<Tooltip title={_item?.url}>
										<Typography
											component="div"
											width="400px"
											sx={{
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
											}}
										>
											<Typography
												component="a"
												sx={{
													textDecoration: 'none',
													color: grey[900],
												}}
												variant="subtitle1"
												href={_item?.url}
												target="_blank"
											>
												{_item?.url}
											</Typography>
										</Typography>
									</Tooltip>
								</TableCell>
								<TableCell>{_item?.user?.firstName}</TableCell>
								<TableCell>{_item?.status}</TableCell>
								<TableCell>
									{moment(_item?.updated_at).format('MM/DD/YYYY')}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Scrollbar>
			<TablePagination
				component="div"
				count={total}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[50, 100, 150, 200]}
				// variant="outlined"
			/>
		</>
	);
};

export default DedicateWebsites;
