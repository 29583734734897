
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
	Grid,
	Avatar,
	Typography,
	Link,
	Breadcrumbs,
	Divider,
	IconButton,
	Drawer,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import SideBar from './sidebar';

// ** Import type
import { FC } from 'react';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
	event.preventDefault();
	console.info('You clicked a breadcrumb.');
}

const Header: FC = () => {
	const { user } = useSelector((state: any) => state.auth);
	const location = useLocation();
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);

	useEffect(() => {
		setOpenDrawer(false);
	}, [location]);

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
			sx={{ textTransform: 'capitalize' }}
		>
			{location.pathname.replace('/', '').replace('_', ' ')}
		</Typography>,
	];

	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				sx={{
					paddingTop: '40.6px',
					backgroundColor: '#FFFFFF',
					paddingBottom: '16.6px',
				}}
			>
				<Grid item sx={{ display: 'flex' }} alignItems="center">
					<IconButton
						edge="start"
						color="inherit"
						aria-label="menu"
						sx={{ mr: 2, display: { xs: 'flex', lg: 'none' } }}
						onClick={() => {
							setOpenDrawer(true);
						}}
					>
						<MenuIcon />
					</IconButton>
					<Drawer
						anchor={'left'}
						open={openDrawer}
						sx={{
							display: { xs: 'flex', lg: 'none' },
							'& .MuiDrawer-paper': {
								backgroundColor: 'transparent',
							},
						}}
						onClose={() => {
							setOpenDrawer(false);
						}}
					>
						<IconButton
							sx={{
								position: 'fixed',
								top: '10px',
								left: 'calc(80vw - 50px)',
								zIndex: 9999,
							}}
							onClick={() => {
								setOpenDrawer(false);
							}}
						>
							<CloseRoundedIcon />
						</IconButton>
						<SideBar mode="drawer" />
					</Drawer>
					<Breadcrumbs separator="›" aria-label="breadcrumb">
						{breadcrumbs}
					</Breadcrumbs>
				</Grid>
				<Grid item sx={{ display: 'flex' }} alignItems="center">
					<Avatar
						alt="User avatar"
						src={user.avatar ? user.avatar : '/images/seeker.png'}
						sx={{ width: 44, height: 44 }}
					/>
					<Typography
						component="p"
						sx={{
							marginLeft: '13px',
							fontFamily: 'Poppins',
							fontStyle: 'normal',
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '24px',
							color: 'primary.main',
						}}
					>
						{user.firstName}
					</Typography>
				</Grid>
			</Grid>
			<Divider sx={{ border: '2px solid #F6FAFF' }} />
		</>
	);
};

export default Header;
