import React, { useState } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import useStyles from './styles/styles';
import YoutubePlayer from './components/YoutubePlayer';

import Shape from './components/Shape';

const Welcome = () => {
	const classes = useStyles();
	let [ishover,setIshover]=useState(false);
	let [ishoverTry,setIshoverTry]=useState(false);
	const clases = {
		header1: {
			fontSize: { xs: '7.9vw !important', md: '3.38vw !important' },
			fontWeight: '600 !important',
			// letterSpacing: "-1.33px !important",
			color: '#bfa600 ! important',
			textAlign: { xs: 'center', md: 'center' },
		},
		contentText: {
			fontSize: { xs: '4.07vw !important', md: '1.15vw !important' },
			fontWeight: '500 !important',
			color: '#ffffff !important',
			textAlign: { xs: 'justify', md: 'justify' },
		},
		buttonSx: {
			width: { md: '22.48vw', xs: '39.95vw' },
			fontSize: { xs: '3.56vw', md: '1.11vw' },
			paddingY: { xs: '5vw', md: '1.5vw' },
			backgroundColor:!ishover ?'#bfa600' :'#EEEE04'
		},
		buttonTry: {
			width: { md: '22.48vw', xs: '39.95vw' },
			fontSize: { xs: '3.56vw', md: '1.11vw' },
			paddingY: { xs: '5vw', md: '1.5vw' },
			backgroundColor:!ishoverTry ?'#bfa600' :'#EEEE04'
		}
		
	};

	return (
		<Grid
			className={classes.sectionBox}
			sx={{
				background: {
					xs: 'linear-gradient(180deg, #000 0%, #181717 100%)',
					md: 'linear-gradient(180deg, #000 11.26%, #181717 99.96%)',
				},
			}}
			id="home"
		>
			<Shape secNumber="1" justifyContent="end" />

			<Grid
				container
				className={classes.containerGrid}
				sx={{
					flexDirection: {
						xs: 'column-reverse',
						md: 'row',
					},
					pt: { xs: '17.3vw', md: '3.07vw' },
					pb: { xs: '0', md: '3vw' },
					
				}}
			>
				<Grid item xs={11} md={4.8}>
					<Typography sx={clases.header1}>Welcome to ADYAI</Typography>
					<Typography sx={clases.contentText}>
						We're thrilled to have you explore the Cardano ecosystem with our beta AI chatbot. As we refine and evolve, your feedback will be invaluable. Dive in, discover, and help us shape the future of DeFi interaction. Enjoy your journey with ADYAI!
					</Typography>
					<Box
						className={classes.flexCenter}
						sx={{
							gap: '2vw',
							p: { md: '1vw', xs: '5vw' },
						}}
					>
						<Button
							variant="contained"
							sx={clases.buttonTry}
							href="/chat"
							onMouseEnter={()=>{setIshoverTry(true)}}
							onMouseLeave={()=>{setIshoverTry(false)}}
						>
							Try ADYAI
						</Button>
						<Button
							variant="contained"
							sx={clases.buttonSx}
							href="https://adayield.io"
							target="_blank"
							onMouseEnter={()=>{setIshover(true)}}
							onMouseLeave={()=>{setIshover(false)}}
						>
							Donate
						</Button>
					</Box>
				</Grid>
				
				<Grid item md={0.5} xs={0}></Grid>
				<Grid
					item
					xs={11}
					md={4.5}
					className={classes.flexCenter}
					sx={{ width: '100%', minHeight: '45vw' }}
				>
					<Grid item md={1} sx={{ display: { xs: 'none', md: 'flex' } }}></Grid>

					<Grid
						item
						xs={12}
						sx={{
							// display: { md: 'none', xs: 'flex' },
							display: 'flex',
							height: {
								sm: '40vw',
								xs: 'auto',
							},
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<YoutubePlayer />
					</Grid>
				</Grid>
				
			</Grid>
		</Grid>
	);
};

export default Welcome;
