import * as actionTypes from '../constants';

export function getAnswer(message: string): { type: string; payload: string } {
	return {
		type: actionTypes.GET_ANSWER_REQUEST,
		payload: message,
	};
}

export function getAnswerAgain(message: string): {
	type: string;
	payload: string;
} {
	return {
		type: actionTypes.GET_ANSWER_AGAIN_REQUEST,
		payload: message,
	};
}

export function getChatHistory(chatId: string = 'last'): {
	type: string;
	payload: string;
} {
	return {
		type: actionTypes.GET_CHAT_HISTORY_REQUEST,
		payload: chatId,
	};
}

export function deleteChatHistory(): { type: string } {
	return {
		type: actionTypes.DELETE_CHAT_HISTORY_REQUEST,
	};
}
export function hideBuyTokensPopup(): { type: string } {
	return {
		type: actionTypes.HIDE_BUY_TOKENS_POPUP,
	};
}
export function t_showBuyTokensPopup(): { type: string } {
	return {
		type: actionTypes.SHOW_BUY_TOKENS_POPUP,
	};
}