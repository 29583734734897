import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// import footer from "./home/footer";
// import section1 from "./home/section1";

import Header from "./home/Header";
import Welcome from "./home/1Welcome";
import Entheogens from "./home/2AdaYield";
import Intro from "./home/3Intro";
import OpenAI from "./home/4OpenAI";
import Ethical from "./home/5Ethical";
import AboutProject from "./home/6AboutProject";
import Footer from "./home/Footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000080",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "Inter", "sans-serif"].join(","),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: ".7vw",
          width: "10vw",
          fontSize: "1vw",
          fontWeight: "700",
          textAlign: "center",
          color: "#FFFFFF",
          textTransform: "none",
          borderRadius: "50vw",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "50vw",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        paragraph: true,
        mb: "1.5vw",
      },
    },
  },
});

const Home = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <>
          <Welcome />
          <Intro />
        </>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Home;
