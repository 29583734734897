import { ThemeOptions } from "@mui/material";
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    lmg: true;
    xl: true;
  }
}

const background = {
  default: "#FFFFFF",
  botMessage: "#F2F2F7",
  userMessage: "#000080",
  modal: "#0B0F19",
};

const primary = {
  main: "#000080",
  contrastText: "#FFFFFF",
};

export const baseThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      lmg: 1600,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
          fontFamily: "Poppins",
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        },
        ".learn-more": {
          fontFamily: "DM Sans",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "29px",
          color: "#000080",
        },
        ".tos": {
          fontFamily: "DM Sans",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "29px",
          color: "#000080",
        },
        ".change-password": {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#000080",
        },
        ".signup": {
          fontFamily: "DM Sans",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: 1.38,
          color: "#000080",
          textDecoration: "none",
        },
        ".content-editable": {
          maxHeight: "165px",
          minHeight: "40px",
          overflowY: "scroll",
          padding: "24px 24px 24px 24px",
          border: "2px solid #A2A5C6",
          borderRadius: "18px",
          background: "#FFFFFF",
          marginTop: "auto",
          marginBottom: "auto",
          width: "100%",
        },
        ".content-editable::-webkit-scrollbar": {
          display: "none",
        },
        ".content-editable > span": {
          whiteSpace: "inherit !important",
        },
        ".content-editable:empty:not(:focus):before": {
          content: "attr(data-text)",
          color: "#8F92A1",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "21px",
        },
        ".triangle-down-human": {
          width: 0,
          height: 0,
          borderLeft: "14px solid transparent",
          borderTop: "10px solid #bfa600",
          marginLeft: "calc(100% - 14px)",
          marginTop: "-1px",
        },
        ".triangle-down-bot": {
          width: 0,
          height: 0,
          borderRight: "14px solid transparent",
          borderTop: "10px solid #F2F2F7",
          marginTop: "-1px",
        },
        ".clipboard-copy": {
          top: "4px",
          right: "4px",
        },
        ".thumbs-icon": {
          top: "4px",
          right: "-68px",
        },
        "::-webkit-scrollbar": {
          // width: "6px",
          // display: "none",
        },
        // "::-webkit-scrollbar-track": {
        //   background: "#f1f1f1",
        // },
        // "::-webkit-scrollbar-thumb": {
        //   background: "#888",
        // },
        // "::-webkit-scrollbar-thumb:hover": {
        //   background: "#555",
        // },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
    },
  },
  direction: "ltr",
  typography: {
    body1: {
      fontSize: "1.125rem",
      lineHeight: 1.6,
      fontWeight: "400",
      fontStyle: "normal",
      fontFamily: "Poppins",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "500",
      fontStyle: "normal",
      fontFamily: "Poppins",
    },
    subtitle2: {
      fontSize: "22px",
      lineHeight: 1.34,
      fontWeight: "400",
      fontStyle: "normal",
      fontFamily: "DM Sans",
    },
  },
  palette: {
    background,
    primary,
  },
};
