import * as actionTypes from '../constants';

export function signIn(
	userInfo: any,
	navigate: any,
	helpers: any
): { type: string; payload: any; navigate: any; helpers: any } {
	return {
		type: actionTypes.POST_SIGNIN_REQUEST,
		payload: userInfo,
		navigate,
		helpers,
	};
}

export function getUserInfo(
	accessToken: string | null,
	navigate: any
): {
	type: string;
	payload: string | null;
	navigate: any;
} {
	return {
		type: actionTypes.GET_USER_INFO_REQUEST,
		payload: accessToken,
		navigate,
	};
}

export function register(userInfo: any, navigate: any, helpers: any) {
	return {
		type: actionTypes.REGISTER_USER_REQUEST,
		payload: userInfo,
		navigate,
		helpers,
	};
}

export function updateProfile(userInfo: any, helpers: any, setLoading: any) {
	return {
		type: actionTypes.UPDATE_PROFILE_REQUEST,
		payload: userInfo,
		helpers,
		setLoading,
	};
}

export function logout() {
	return {
		type: actionTypes.LOGOUT,
	};
}

export function sendHelpEmail(callBack: any) {
	return {
		type: actionTypes.SEND_HELP_EMAIL_REQUEST,
		callBack,
	};
}

export function registerNewsletter(email: string, callBack: any) {
	return {
		type: actionTypes.REGISTER_NEWSLETER_REQUEST,
		payload: email,
		callBack,
	};
}
