import { call, all, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../constants';

import apiClient from '../apiClient';
import { getAccessToken } from '../../utils/jwt';

import { AxiosResponse } from 'axios';
import { Console } from 'console';

async function shooterGetAnswer(
	message: string
): Promise<AxiosResponse<any, any>> {
	return apiClient.post(
		'/chat/message',
		{ finalChat: message },
		{ headers: { Authorization: getAccessToken() } }
	);
}

function* getAnswer(): any {
  return yield takeLatest(
    actionTypes.GET_ANSWER_REQUEST,
    function* (action: any) {
      try {
        yield put({
          type: actionTypes.ADD_MESSAGE,
          payload: action.payload,
        });

        // Use `axios.get` or `axios.post` to make the HTTP request
        const response: AxiosResponse<any, any> = yield call(
          shooterGetAnswer,
          action.payload
        );

        const data = response.data; // Access the `data` property of AxiosResponse

       
			console.log(data)
        // Check if tokens need to be bought after receiving the response
        if (data && data.totalTokenConsumed >= data.totalTokenBought) {
			console.log(data)
          yield put({
            type: actionTypes.SHOW_BUY_TOKENS_POPUP,
          });
        }
		else{
			 // Continue with normal success handling
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_SUCCESS,
          payload: {
            ...response, // You may want to include the entire response in the payload
          },
        });
		}

      } catch (error) {
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_ERROR,
        });
        console.log(error);
      }
    }
  );
}


function* getAnswerAgain(): any {
	return yield takeLatest(
		actionTypes.GET_ANSWER_AGAIN_REQUEST,
		function* (action: any) {
			try {
				const data: Promise<AxiosResponse<any, any>> = yield call(
					shooterGetAnswer,
					action.payload
				);
				yield put({
					type: actionTypes.GET_ANSWER_REQUEST_SUCCESS,
					payload: {
						...data,
					},
				});
			} catch (error) {
				yield put({
					type: actionTypes.GET_ANSWER_REQUEST_ERROR,
				});
				console.log(error);
			}
		}
	);
}

async function shooterGetChatHistory(
	chatId: string
): Promise<AxiosResponse<any, any>> {
	return apiClient.get(`/chat/message/${chatId}`, {
		headers: { Authorization: getAccessToken() },
	});
}

function* getChatHistory(): any {
	return yield takeLatest(
		actionTypes.GET_CHAT_HISTORY_REQUEST,
		function* (action: any) {
			try {
				const data: Promise<AxiosResponse<any, any>> = yield call(
					shooterGetChatHistory,
					action.payload
				);
				yield put({
					type: actionTypes.GET_CHAT_HISTORY_REQUEST_SUCCESS,
					payload: {
						...data,
					},
				});
			} catch (error) {
				console.log(error);
			}
		}
	);
}

async function shooterDeleteChatHistory(): Promise<AxiosResponse<any, any>> {
	return apiClient.delete(`/chat/message/all`, {
		headers: { Authorization: getAccessToken() },
	});
}

function* deleteChatHistory(): any {
	return yield takeLatest(
		actionTypes.DELETE_CHAT_HISTORY_REQUEST,
		function* (action: any) {
			try {
				const data: Promise<AxiosResponse<any, any>> = yield call(
					shooterDeleteChatHistory
				);
				console.log(data);
				yield put({
					type: actionTypes.DELETE_CHAT_HISTORY_REQUEST_SUCCESS,
					payload: {
						...data,
					},
				});
				yield put({
					type: actionTypes.GET_CHAT_HISTORY_REQUEST,
					payload: 'last',
				});
			} catch (error) {
				console.log(error);
			}
		}
	);
}
function* hideBuyTokensPopup(): any {
  yield takeLatest(
    actionTypes.HIDE_BUY_TOKENS_POPUP,
    function* () {
      // You can add additional logic here if needed
      yield put({
        type: actionTypes.HIDE_BUY_TOKENS_POPUP,
      });
    }
  );
}
function* showBuyTokensPopup(): any {
  yield takeLatest(
    actionTypes.SHOW_BUY_TOKENS_POPUP,
    function* () {
      // You can add additional logic here if needed
      yield put({
        type: actionTypes.SHOW_BUY_TOKENS_POPUP,
      });
    }
  );
}

export default function* runBlockingCallsExample() {
	yield all([
		getAnswer(),
		getChatHistory(),
		deleteChatHistory(),
		getAnswerAgain(),
		hideBuyTokensPopup(),
	]);
}

