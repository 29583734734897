import * as actionTypes from '../constants';

// ** Import type
import { Message } from '../../types/message';

const initialState: {
	end: boolean;
	loading: boolean;
	messages: Message[];
	answerError: boolean;
	showBuyTokensPopup: boolean
} = {
	end: true,
	loading: false,
	messages: [],
	answerError: false,
	showBuyTokensPopup: false
};

const reducer = (
	prevState = initialState,
	action: { type: string; payload: any }
) => {
	switch (action.type) {
		// ** When user press enterKey or send message button
		case actionTypes.ADD_MESSAGE:
			return {
				loading: true,
				end: prevState.end,
				messages: [
					{
						sender: 'human',
						message: action.payload,
					},
					...prevState.messages,
				],
			};
		// ** After fetch answer
		case actionTypes.GET_ANSWER_REQUEST_SUCCESS:
			let messages: Message[] = [];
			action.payload?.data?.messages.reverse().map((_item: any) => {
				if (_item.role !== 'user') {
					messages.push({
						id: _item.id,
						sender: 'bot',
						message: _item.content,
						refer: JSON.parse(_item.refer),
					});
				}
				return [];
			});
			return {
				loading: false,
				end: action.payload?.data?.end,
				messages: [...messages, ...prevState.messages],
			};

		case actionTypes.GET_ANSWER_REQUEST:
		case actionTypes.GET_ANSWER_AGAIN_REQUEST:
			return {
				...prevState,
				answerError: false,
			};
		case actionTypes.GET_ANSWER_REQUEST_ERROR:
			return {
				...prevState,
				loading: false,
				answerError: true,
			};
			case actionTypes.SHOW_BUY_TOKENS_POPUP:
  return {
    ...prevState,
    loading: false,
    showBuyTokensPopup: true, // Add a new property to your state
  };
  case actionTypes.HIDE_BUY_TOKENS_POPUP:
  return {
    ...prevState,
    loading: false,
    showBuyTokensPopup: false, // Add a new property to your state
  };

		case actionTypes.GET_CHAT_HISTORY_REQUEST_SUCCESS:
			let chatHistory: Message[] = [];
			action.payload?.data?.messages.reverse().map((_item: any) => {
				if (_item.role !== 'user') {
					chatHistory.push({
						id: _item.id,
						sender: 'bot',
						message: _item.content,
						refer: JSON.parse(_item.refer),
					});
				} else {
					chatHistory.push({
						id: _item.id,
						sender: 'human',
						message: _item.content,
					});
				}
				return 0;
			});
			return {
				end: action.payload?.data?.end,
				messages: [...prevState.messages, ...chatHistory],
			};

		case actionTypes.DELETE_CHAT_HISTORY_REQUEST_SUCCESS:
			return {
				end: true,
				messages: [],
			};
		default:
			return prevState;
	}
};

export default reducer;
